import {inject, Injectable, signal} from "@angular/core";
import {IMetric} from "../../api/metric/i.metric.response";
import {MetricApiService} from "../../api/metric/metric.api.service";

@Injectable()
export class MetricService {

  readonly #metricApiService = inject(MetricApiService);

  public readonly metric = signal<IMetric | null>(null);

  public constructor() {
    this.initMetric();
  }

  public initMetric() {
    this.#metricApiService.execute().subscribe((metric) => {
      this.metric.set(metric.data);
    });
  }

}
